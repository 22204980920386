import { createSlice } from '@reduxjs/toolkit';
import { IWELFARE } from '../../utilities/dataHelper/welfare';

const welfareFoodCartSlice = createSlice({
  name: 'foodCart',
  initialState: { cart: [] as FOODITEM[], temporaryCart: [] as FOODITEM[] },
  reducers: {
    removeFromCart: (state, action: { payload: FOODITEM }) => {
      state = {
        cart: state.cart.filter(
          (item) => item.welfareId !== action.payload.welfareId,
        ),
        temporaryCart: state.temporaryCart.filter(
          (item) => item.welfareId !== action.payload.welfareId,
        ),
      };
      return state;
    },
    addToCart: (state, action: { payload: string }) => {
      const itemExists = state.cart.find(fdItem => fdItem.welfareId ===  action.payload);
      if (itemExists) state.cart = state.cart.filter((item) => item.welfareId !== action.payload);
      
      const newItem = state.temporaryCart.find(fdItem => action.payload === fdItem.welfareId);
     
      if (newItem) state.cart.push(newItem);
      state = {
        cart: state.cart,
        temporaryCart: state.temporaryCart,
      };

    },
    modifyCart: (state, action: { payload: FOODITEM | any }) => {
      let item: any =
        state.temporaryCart &&
        state.temporaryCart.find(
          (welfare) => welfare?.welfareId === action.payload.welfareId,
        );

      if (item) {
        item.castAndCrewPerDay = action.payload.castAndCrewPerDay;
        item.cityOfShoot = action.payload.cityOfShoot;
        item.commencementDate = action.payload.commencementDate;
        item.country = action.payload.country;
        item.deliveryAddress = action.payload.deliveryAddress;
        item.deliveryDate = action.payload.deliveryDate;
        item.deliveryTime = action.payload.deliveryTime;
        item.productionDuration = action.payload.productionDuration;
        item.squareMealsPerDay = action.payload.squareMealsPerDay;
        item.stateOfShoot = action.payload.stateOfShoot;
        item.welfareService = 'food';
        item.currentFood = action.payload.currentFood;

        if (!item.ServicesRequired) item.ServicesRequired = [];
        const currentFoodInCart = item.ServicesRequired.find(
          (fd: { id: any; }) => fd.id === action.payload.currentFood?.id,
        );
        // if(currentFoodInCart).log(true)
        // if(!currentFoodInCart).log(false)
        if (!currentFoodInCart) {
          action.payload.currentFoodInCart = [];
          if (action.payload.currentFood)
            item.ServicesRequired.push(action.payload.currentFood);
        }
        item.ServicesRequired = item.ServicesRequired?.map((fd: { id: any; }) => {
          if (fd.id === action.payload.currentFood?.id)
            return action.payload.currentFood;
          return fd;
        });

        item.ServicesRequired = item.ServicesRequired.filter(
          (ser: { cost: number; }) => ser.cost > 0,
        );

        item.subTotal = item.ServicesRequired.reduce((a: any, b: { cost: any; }) => a + b.cost, 0);
        
        if (item.subTotal >= 300000 && item.business.discount > 0) {
          item.serviceDiscount = (item.business.discount / 100) * item.subTotal;
          item.total = item.subTotal - item.serviceDiscount;
        } else {
          item.total = item.subTotal;
        }
      } else {
        if (!action.payload.ServicesRequired)
          action.payload.ServicesRequired = [];
        if (state.temporaryCart) state.temporaryCart.push(action.payload);
      }
    },

    emptyWelfareFoodCart: (state) => {
      state.cart = [];
      state.temporaryCart = [];
      return state;
    },
  },
});

export const welfareFoodCartReducer = welfareFoodCartSlice.reducer;

export const { addToCart, removeFromCart, emptyWelfareFoodCart, modifyCart } =
  welfareFoodCartSlice.actions;

export interface FOODITEM {
  welfareService: string;
  currentFood?: WelfareItem;
  business?: IWELFARE;
  country?: string;
  welfareId?: string;
  paymentId?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  trans_ref?: string;
  paymentStatus?: string;
  paymentType?: string;
  stateOfShoot: string;
  cityOfShoot: string;
  commencementDate: string;
  productionDuration: number;
  squareMealsPerDay: number;
  castAndCrewPerDay: number;
  deliveryAddress: string;
  deliveryDate: string;
  deliveryTime: string;
  ServicesRequired?: WelfareItem[];
  subTotal?: number |  any;
  total?: number;
  serviceDiscount: number;
}

export interface WelfareItem {
  mealType: 'continental' | 'intercontinental';
  mealCategory: 'breakfast' | 'lunch' | 'dinner';
  category: 'food';
  name: string;
  priceRange: string;
  budget: number;
  numberOfDays: number;
  quantity: number;
  cost: number;
  id: string;
}

export enum MEAL_TYPE {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  DINNER = 'dinner',
}
