import { createSlice } from '@reduxjs/toolkit';
import {  Delivery, IMEDIA, IRENTAL } from '../../utilities/dataHelper/equipment';

const equipmentCartSlice = createSlice({
  name: 'equipmentCart',
  initialState: { temporaryCart: [] as RentalCartItem[], cart: [] as RentalCartItem[] } as EquipmentCart,
  reducers: {
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter(item => item.rentalsId !== action.payload.rentalsId);
      state.temporaryCart = state.temporaryCart.filter(item => item.rentalsId !== action.payload.rentalsId);
      return state;
    },
    addToCart: (state, action: { payload: string }) => {
      const itemExists = state.cart.find(eqItem => eqItem.rentalsId ===  action.payload);
      if (itemExists) state.cart = state.cart.filter((item) => item.rentalsId !== action.payload);
      
      const newItem = state.temporaryCart.find(eqItem => eqItem.rentalsId ===  action.payload);
     
      if (newItem) state.cart.push(newItem);
      state = {
        cart: state.cart,
        temporaryCart: state.temporaryCart,
      };

    },
    modifyCart: (state, action: { payload: RentalCartItem }) => {
      const item = state.temporaryCart && state.temporaryCart.find((rental) => rental.rentalsId === action.payload.rentalsId);
      
      if (item) {
        item.rentalsId = action.payload.rentalsId;
        item.addressOfDelivery = action.payload.addressOfDelivery;
        item.cityOfShoot = action.payload.cityOfShoot;
        item.commencementDate = action.payload.commencementDate;
        item.country = action.payload.country;
        item.dateOfDelivery = action.payload.dateOfDelivery;
        item.daysToKeepVehicle = action.payload.daysToKeepVehicle;
        item.deliveryAddress = action.payload.deliveryAddress;
        item.deliveryDate = action.payload.deliveryDate;
        item.deliveryState = action.payload.deliveryState;
        item.deliveryTime = action.payload.deliveryTime;
        item.deliveryVehicle = action.payload.deliveryVehicle;
        item.needDelivery = action.payload.needDelivery;
        item.pickUpDate = action.payload.pickUpDate;
        item.pickUpTime = action.payload.pickUpTime;
        item.productionDuration = action.payload.productionDuration;
        item.stateOfShoot = action.payload.stateOfShoot;
        item.rental = action.payload.rental;
        item.grandTotal = action.payload.grandTotal;
        
        if (!item.equipmentNeeded) item.equipmentNeeded = [];
        const currentItem = item.equipmentNeeded?.find(eqp => eqp.id === action.payload.currentEquipmentNeeded?.id);
        if (!currentItem && action.payload.currentEquipmentNeeded) {
          item.equipmentNeeded?.push(action.payload.currentEquipmentNeeded);
        }

        item.equipmentNeeded = item.equipmentNeeded && item.equipmentNeeded.map((eqp) => {
          if (eqp && eqp.id === action.payload.currentEquipmentNeeded?.id) return action.payload.currentEquipmentNeeded;
          return eqp;
        });

        item.equipmentNeeded = item.equipmentNeeded && item.equipmentNeeded.filter(ser => ser.amount > 0);

        item.subTotal = item.equipmentNeeded.reduce((a, b) => a + b.amount, 0);
        item.securityFee = (item.rental?.securityFee / 100) * item.subTotal;
        if (item.subTotal >= 700000 && item.rental?.discount) {
          item.total = item.subTotal - (0.1 * item.subTotal) + item.securityFee;
        } else {
          item.total = item.subTotal + item.securityFee;
        }

        

        item.deliveryCost = action.payload.needDelivery ? Number(action.payload.delivery?.deliveryCost || 0) : 0;

        item.deliveryVehicleCost =  action.payload.needDelivery ? Number(action.payload?.deliveryVehicleCost || 0) : 0;



        item.deliveryDiscount = action.payload.needDelivery ? (0.1 * Number(action.payload?.deliveryVehicleCost || 0)) : 0;
        if (item.daysToKeepVehicle >= 4 && action.payload.rental?.equipments.tenPercentAfter4days) {
          item.vehicleOrderSubTotal = action.payload.needDelivery ? (Number(action.payload?.deliveryVehicleCost || 0)) : 0;
          item.vehicleOrderTotal = action.payload.needDelivery ? (Number(action.payload?.deliveryVehicleCost || 0))  - Number(item?.deliveryDiscount || 0) : 0;
        } else {
          item.vehicleOrderSubTotal = item?.deliveryVehicleCost;
          item.vehicleOrderTotal = item?.deliveryVehicleCost;
        }


        item.grandTotal = item.total +  Number(item?.vehicleOrderTotal || 0) + Number(item?.deliveryCost || 0);
     
      } else {
        if (!state.temporaryCart) state.temporaryCart = [];
        if (action.payload.currentEquipmentNeeded) action.payload.equipmentNeeded = [];
        state.temporaryCart.push(action.payload);
        // return state;
      }
    },
    emptyCart: (state: { temporaryCart: RentalCartItem[]; cart: RentalCartItem[]; }) => {
      state = { temporaryCart: [] as RentalCartItem[], cart: [] as RentalCartItem[] };
      return state;
    },
  },
});

export const equipmentCartReducer = equipmentCartSlice.reducer;


export const {
  addToCart, removeFromCart,
  emptyCart, modifyCart,
} = equipmentCartSlice.actions;

export interface RentalCartItem {
  media: IMEDIA;
  equipmentName?: string;
  instrumentName?: string;
  rental: IRENTAL;
  equipmentNeeded?: EquipmentNeeded[];
  country: string;
  addressOfDelivery: string;
  dateOfDelivery: string;
  // timeOfDelivery: string;
  rentalsId: string
  paymentId?: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  trans_ref?: string
  paymentStatus?: string
  paymentType?: string
  stateOfShoot: string
  cityOfShoot: string
  commencementDate: string
  productionDuration: number
  needDelivery: boolean
  deliveryState: string
  deliveryAddress: string
  deliveryDate: string
  deliveryTime: string
  pickUpDate: string
  pickUpTime: string
  deliveryVehicle: string
  daysToKeepVehicle: number
  currentEquipmentNeeded?: EquipmentNeeded;
  total?: number;
  delivery: Delivery | null | undefined;
  subTotal?: number;
  securityFee?: number;
  deliveryCost?: number;
  vehicleOrderTotal?: number;
  deliveryVehicleCost?: number;
  grandTotal?: number;
  deliveryDiscount?: number;
  vehicleOrderSubTotal?: number;
}

export interface EquipmentNeeded {
  quantity: number;
  days: number;
  name: string;
  costPerDay: number;
  amount: number;
  category: string;
  id: string;
  // total: number
}

interface EquipmentCart {
  temporaryCart: RentalCartItem[];
  cart: RentalCartItem[]
}
