import { createSlice } from '@reduxjs/toolkit';
import { ITRANSPORT } from '../../utilities/dataHelper/transport';

const transportCart = createSlice({
  name: 'transportCart',
  initialState: {
    temporaryCart: [] as TransportCartItem[],
    cart: [] as TransportCartItem[],
  } as TransportCart,
  reducers: {
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter(
        (item) => item.transportId !== action.payload.transportId,
      );
      state.temporaryCart = state.temporaryCart.filter(
        (item) => item.transportId !== action.payload.transportId,
      );
      return state;
    },
    addToCart: (state, action: { payload: string }) => {
      const itemExists = state.cart.find(eqItem => eqItem.transportId ===  action.payload);
      if (itemExists) state.cart = state.cart.filter((item) => item.transportId !== action.payload);
      
      const newItem = state.temporaryCart.find(eqItem => eqItem.transportId ===  action.payload);
     
      if (newItem) state.cart.push(newItem);
      state = {
        cart: state.cart,
        temporaryCart: state.temporaryCart,
      };

    },
    modifyCart: (state, action: { payload: TransportCartItem }) => {
      const item =
        state.temporaryCart &&
        state.temporaryCart.find(
          (transport) => transport.transportId === action.payload.transportId,
        );
      if (item) {
        item.transportId = action.payload.transportId;
        item.stateOfShoot = action.payload.stateOfShoot;
        item.cityOfShoot = action.payload.cityOfShoot;
        item.commencementDate = action.payload.commencementDate;
        item.productionDuration = action.payload.productionDuration;
        item.business = action.payload.business;
        item.total = action.payload.total;

        if (!item.vehiclesRequired) {
          item.vehiclesRequired = [];

          if (action.payload.currentVehicle)
            item.vehiclesRequired.push(action.payload.currentVehicle);
        }

        if (action.payload.currentVehicle) {
          const vehicleInCart = item.vehiclesRequired?.find(
            (vehicle) =>
              vehicle.id ===
              action.payload.currentVehicle?.id,
          );
          if (!vehicleInCart) {
            item.vehiclesRequired.push(action.payload.currentVehicle);
          }
            
          item.vehiclesRequired = item.vehiclesRequired?.map((vhc) => {
            if (
              vhc.id === action.payload.currentVehicle?.id
            )
              return action.payload.currentVehicle;
            return vhc;
          });

          item.vehiclesRequired = item.vehiclesRequired?.filter(
            (vhc) => vhc.cost > 0,
          );
        }
        item.total = item.vehiclesRequired?.reduce((a, b) => a + b.cost, 0);

      } else {
        state.temporaryCart.push(action.payload);
      }
    },
    emptyCart: (state) => {
      state = {
        temporaryCart: [] as TransportCartItem[],
        cart: [] as TransportCartItem[],
      };
      return state;
    },
  },
});

export const transportCartReducer = transportCart.reducer;

export const { addToCart, removeFromCart, emptyCart, modifyCart } =
  transportCart.actions;

export interface TransportCartItem {
  transportId: string;
  paymentId?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  trans_ref?: string;
  paymentStatus?: string;
  paymentType?: string;
  stateOfShoot: string;
  cityOfShoot: string;
  commencementDate: string;
  productionDuration: number;
  vehiclesRequired?: Vehicle[];
  currentVehicle?: Vehicle;
  business: ITRANSPORT;
  total?: number;
}

export interface Vehicle {
  id: string;
  category: 'van' | 'hiace' | 'equipment truck' | 'coaster' | string;
  name: string;
  priceRange?: string;
  amenities: string;
  budget: number;
  numberOfDays: number;
  quantity: number;
  cost: number;
  numberOfSeaters: number;
  // category: string;
}
interface TransportCart {
  temporaryCart: TransportCartItem[];
  cart: TransportCartItem[];
}
