import styled from 'styled-components';
import { FMMTheme } from '../theme';

export const HeroStyle = styled.div`
  width: 100%;
  display: flex;
  margin-top: 80px;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: 'FMMFont', sans-serif !important;

  .heroInfo {
    width: 90%;
    max-width: 985px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 72px;

    .tag {
      margin-bottom: 8px;
    }

    .title {
      font-size: 48px;
      font-weight: 700;
      color: ${FMMTheme.colors.grey_900};
      text-align: center;
      line-height: 1.5;
      margin-bottom: 20px;
      font-family: 'FMMFont', sans-serif !important;

      .highlight {
        color: ${FMMTheme.colors.teal_500};
      }
    }

    .subtitle {
      font-size: 20px;
      font-weight: 300;
      color: ${FMMTheme.colors.grey_700};
      max-width: 610px;
      text-align: center;
      line-height: 1.5;
      letter-spacing: 0;
      margin-bottom: 40px;
    }

    .cta {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }

  .carouselWrapper {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    height: 394px;
    margin-bottom: 100px;
  }
  .carousel {
    height: 392px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 32px;
    position: absolute;
    left: 0;
    top: 0;

    .bigImage,
    .smallImage {
      border-radius: 20px;
      display: grid;
      place-content: center;
      background-color: #fff;
      position: relative;
      width: 305px;
      overflow: hidden;
    }
    .bigImage {
      height: 100%;
    }
    .smallImage {
      height: 80%;
    }
    .carImg {
      object-fit: cover;
    }
  }

  .gridWrapper {
    width: 80vw;
    height: 945px;
    position: absolute;
    top: -34px;
    left: transform(translateX(-50%));
    z-index: -1;
    display: grid;
    place-items: center;
    overflow: hidden;
    .grid {
      width: 200%;
      height: 100%;
    }
  }
  .mapWrapper {
    width: 423.92px;
    height: 511.97px;
    position: absolute;
    top: 18px;
    left: transform(translateX(-50%));
    z-index: -1;
    .map {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 56px;
    .heroInfo {
      width: 100%;
      padding-inline: 64px;
      .title {
        font-size: 36px;
        margin-bottom: 16px;
      }
      .subtitle {
        font-size: 16px;
        padding-inline: 16px;
      }
    }
    .cta {
      width: 100%;
      .heroButton {
        height: 40px;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .carouselWrapper {
      height: 294px;
      margin-bottom: 72px;
      .carousel {
        height: 292px;
      }
      .bigImage,
      .smallImage {
        width: 228px;
      }
    }
    .gridWrapper {
      height: 700px;
    }
  }
  @media (max-width: 576px) {
    margin-top: 40px;
    .heroInfo {
      padding-inline: 24px;
      .title {
        font-size: 24px;
        line-height: 32px;
        padding-inline: 16px;
        margin-bottom: 12px;
      }
      .subtitle {
        padding-inline: 24px;
      }
    }
    .mapWrapper {
      width: 100vw !important;
      display: flex;
      justify-content: center;
      .map {
        width: 65%;
        margin-inline: auto;
      }
    }
    .gridWrapper {
      width: 100vw !important;
      margin-top: -24px;
    }
    .cta {
      flex-direction: column;
      .heroButton {
        max-width: 100%;
        height: 48px;
      }
    }
    .carouselWrapper {
      height: 254px;
      margin-bottom: 56px;
      .carousel {
        height: 252px;
      }
      .bigImage,
      .smallImage {
        width: 196px;
      }
    }
  }
`;

export const OurClientsStyle = styled.div`
  width: 100%;
  min-height: 112px;
  padding-inline: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 100px;
  font-family: 'FMMFont', sans-serif !important;

  .text {
    width: 100%;
    max-width: 416px;
    .title {
      font-size: 24px;
      font-weight: 700;
      color: ${FMMTheme.colors.grey_900};
      margin-bottom: 8px;
    }

    .subText {
      font-size: 16px;
      font-weight: 400;
      color: ${FMMTheme.colors.grey_700};
      line-height: 24px;
    }
  }
  .clientLogos {
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: flex-end;
    column-gap: 40px;

    .clientLogoWrapper {
      width: 80px;
      height: 61.5px;

      .clientLogo {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @media (max-width: 1024px) {
    padding-inline: 64px;
    gap: 24px;
    margin-bottom: 72px;
    .text {
      max-width: 50%;
      .title {
        font-size: 20px;
      }
      .subText {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .clientLogos {
      max-width: 50%;
      justify-content: center;
      column-gap: 24px;
      row-gap: 16px;
      .clientLogoWrapper {
        width: 57px;
        height: 21px;
      }
    }
  }

  @media (max-width: 576px) {
    padding-inline: 24px;
    flex-direction: column;
    .text {
      max-width: 100%;
      .title {
        font-size: 18px;
      }
    }
    .clientLogos {
      max-width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const OurServicesStyle = styled.div`
  width: 100%;
  min-height: 764px;
  padding-inline: 80px;
  padding-block: 88px;
  background-color: ${FMMTheme.colors.primary_50};
  font-family: 'FMMFont', sans-serif !important;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 72px;
  }

  .tag {
    margin-bottom: 20px;
  }

  .filmWheel {
    width: 200px;
    height: 200px;
    mix-blend-mode: color-burn;

    .wheel {
      width: 100%;
      height: 100%;
    }
  }

  .servicesText {
    width: 100%;
    max-width: 624px;
    .title {
      font-size: 32px;
      font-weight: 700;
      color: ${FMMTheme.colors.grey_900};
    }
    .subText {
      font-size: 20px;
      font-weight: 400;
      color: ${FMMTheme.colors.grey_700};
      line-height: 30px;
    }
  }

  .serviceCards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .serviceCard {
    background-color: ${FMMTheme.colors.white};
    border-radius: 12px;
    padding: 32px 24px;

    .iconWrapper {
      display: grid;
      place-content: center;
      width: 72px;
      height: 72px;
      background-color: ${FMMTheme.colors.primary_50};
      border-radius: 50%;
      color: ${FMMTheme.colors.primary_500};
      margin-bottom: 24px;

      .icon {
        color: ${FMMTheme.colors.primary_500};
        width: 32px;
        height: 32px;
        position: relative;
      }
    }

    .cardTitle {
      font-size: 24px;
      font-weight: 700;
      color: ${FMMTheme.colors.grey_900};
      margin-bottom: 12px;
    }

    .cardSubText {
      font-size: 16px;
      font-weight: 400;
      color: ${FMMTheme.colors.grey_700};
      line-height: 24px;
    }
  }
  /* Media Queries for Tablet */
  @media (max-width: 1024px) {
    padding-inline: 64px;
    padding-block: 72px;

    .serviceCards {
      grid-template-columns: repeat(2, 1fr);
    }

    .top {
      gap: 16px;
      align-items: center;
      margin-bottom: 56px;

      .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
      }

      .subText {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .filmWheel {
      width: 120px;
      height: 120px;
    }
    .serviceCard:nth-child(3) {
      grid-column: span 2;
    }

    .serviceCard {
    }
  }

  /* Media Queries for Mobile */
  @media (max-width: 576px) {
    padding-inline: 24px;
    .top {
      margin-bottom: 40px;
      .tag {
        margin-bottom: 16px;
      }
      .title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 8px;
      }
      .subText {
        padding-right: 8px;
      }
    }
    .filmWheel {
      display: none;
    }
    .serviceCards {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .serviceCard {
        padding: 32px 20px;
      }

      .serviceCard {
        .iconWrapper {
          width: 64px;
          height: 64px;
          .icon {
            width: 28px;
            height: 28px;
          }
        }
        .cardTitle {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
`;

export const GetStartedStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 112px;
  padding-bottom: 0;
  margin-bottom: 120px;
  overflow-x: hidden;
  font-family: 'FMMFont', sans-serif !important;

  .getStartedHeader {
    width: 100%;
    max-width: 588px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 104px;

    .tag {
      margin-bottom: 20px;
    }

    .sectionTitle {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .sectionSubText {
      font-size: 20px;
      color: ${FMMTheme.colors.grey_700};
      line-height: 30px;
      font-weight: 400;
    }
  }

  .actionsWrapper {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 120px;

    .action {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 120px;
    }

    .getStartedText {
      width: 100%;
      display: flex;
      gap: 24px;

      .numberWrapper {
        width: 30%;
        min-width: 36px;
        max-width: 48px;
      }

      .number {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: ${FMMTheme.colors.grey_200};
        display: grid;
        place-content: center;
        font-size: 32px;
        font-weight: 700;
        color: ${FMMTheme.colors.grey_900};
      }

      .infoWrapper {
        width: fit-content;

        .title {
          font-size: 24px;
          font-weight: 700;
          color: ${FMMTheme.colors.grey_900};
          margin-bottom: 12px;
        }
        .subText {
          font-size: 16px;
          font-weight: 400;
          color: ${FMMTheme.colors.grey_700};
          line-height: 24px;
          margin-bottom: 32px;
        }
        .getStartedBtn {
          font-size: 14px;
          font-weight: 700;
          color: ${FMMTheme.colors.teal_500};
          cursor: pointer;
          background-color: ${FMMTheme.colors.teal_50};
          padding-block: 10px;
          width: 123px;
          border-radius: 12px;
          transition: transform 0.3s ease-in-out,
            background-color 0.3s ease-in-out;
          border: none;
          z-index: 10;
          &:hover {
            transform: scale(1.025);
            background-color: ${FMMTheme.colors.teal_100};
          }
        }
      }
    }

    .getStartedImage {
      width: 100%;
      max-width: 522px;
      height: fit-content;
      border-radius: 20px;
      display: grid;
      place-content: center;
      background-color: #fff;
      box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.03);
      overflow: hidden;
    }
  }

  @media (max-width: 1024px) {
    padding-inline: 64px;
    margin-bottom: 84px;

    .getStartedHeader {
      max-width: 459px;
      margin-bottom: 88px;

      .sectionTitle {
        font-size: 28px;
        margin-bottom: 12px;
      }
      .sectionSubText {
        font-size: 16px;
      }
    }

    .actionsWrapper {
      width: 100%;
      gap: 88px;
      .action {
        flex-direction: column;
        gap: 27px;

        .getStartedText {
          .infoWrapper {
            max-width: 618px;
            .title {
              font-size: 20px;
              margin-bottom: 8px;
            }
            .subText {
              margin-bottom: 24px;
            }
          }
        }
        .getStartedImage {
          max-width: 100%;
          /* padding: 33px 38px; */
        }
      }
      .reverse {
        flex-direction: column-reverse;
      }
    }
  }
  @media (max-width: 576px) {
    padding-inline: 24px;
    .getStartedHeader {
      max-width: 100%;
      margin-bottom: 72px;

      .tag {
        margin-bottom: 16px !important;
      }
      .sectionTitle {
        font-size: 20px;
      }
      .sectionSubText {
        font-size: 16px;
      }
    }

    .actionsWrapper {
      gap: 40px;
    }

    .getStartedText {
      flex-direction: column;
      .numberWrapper {
        width: 100%;
        .number {
          width: 36px;
          height: 36px;
          background-color: ${FMMTheme.colors.primary_500};
          font-size: 20px;
          color: ${FMMTheme.colors.white};
        }
        .title {
          font-size: 16px !important;
          margin-bottom: 20px;
        }
      }
    }
    /* .getStartedImage {
      height: 240px !important;
    } */
  }
`;

export const OurCommunityStyle = styled.div`
  width: 100%;
  padding-inline: 80px;
  overflow-x: hidden;
  margin-bottom: 120px;
  font-family: 'FMMFont', sans-serif !important;

  .communityHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    .sectionTitle {
      font-size: 40px;
      font-weight: 700;
      color: ${FMMTheme.colors.grey_900};
      margin-bottom: 16px;
      text-align: center;
    }
    .sectionSubText {
      font-size: 20px;
      font-weight: 400;
      color: ${FMMTheme.colors.grey_700};
      text-align: center;
    }
  }

  .communitySignUp {
    width: 100%;
    border-radius: 20px;
    padding: 88px 80px;

    .signUpHeader {
      width: 100%;
      display: flex;
      margin-bottom: 77px;

      .signupBtnWrapper {
        width: 100%;
        max-width: 220px;
        display: flex;
        justify-content: flex-end;
      }

      .textWrapper {
        width: calc(100% - 40px - 180px);
        .signUpTitle {
          font-size: 32px;
          font-weight: 700;
          color: ${FMMTheme.colors.grey_900};
          margin-bottom: 16px;
        }
        .signUpSubText {
          font-size: 20px;
          font-weight: 400;
          color: ${FMMTheme.colors.grey_700};
          max-width: 757px;
          letter-spacing: 0;
          line-height: 30px;
        }
        .smScreen {
          display: none;
        }
      }
    }

    .benefitsWrapper {
      width: 100%;
      display: flex;
      column-gap: 24px;
      row-gap: 48px;
      flex-wrap: wrap;

      .benefitCard {
        width: calc(50% - 12px);
        background-color: ${FMMTheme.colors.white};
        border-radius: 20px;
        padding: 38px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .benefitIcon {
          width: 200px;
          height: 200px;
          margin-bottom: 16px;

          .icon {
            width: 100%;
            height: 100%;
          }
          .profileIcon {
            width: 90%;
            height: 90%;
          }
        }

        .cardTitle {
          font-size: 24px;
          font-weight: 700;
          color: ${FMMTheme.colors.grey_900};
          margin-bottom: 12px;
        }

        .cardSubText {
          font-size: 16px;
          font-weight: 400;
          color: ${FMMTheme.colors.grey_700};
          line-height: 24px;
        }
      }
    }
  }

  .provider {
    margin-bottom: 80px;
  }

  @media (max-width: 1024px) {
    padding-inline: 64px;
    margin-bottom: 80px;

    .signupBtnWrapper {
      justify-content: flex-start !important;
    }

    .communityHeader {
      margin-bottom: 72px;

      .sectionTitle {
        font-size: 28px;
        margin-bottom: 12px;
        line-height: 38px;
      }
      .sectionSubText {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .communitySignUp {
      padding: 56px 32px 60px;
      .signUpHeader {
        flex-direction: column;
        gap: 32px;
        margin-bottom: 65px;
        .textWrapper {
          width: 100%;
        }
      }
      .signUpSubText {
        font-size: 16px !important;
        line-height: 24px !important;
        max-width: 100% !important;
      }
      .signUpTitle {
        font-size: 24px !important;
        margin-bottom: 12px;
      }

      .benefitsWrapper {
        column-gap: 22px;
        row-gap: 40px;
        .benefitCard {
          width: calc(50% - 11px);

          .benefitIcon {
            width: 160px;
            height: 160px;
          }

          .cardTitle {
            font-size: 20px !important;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    padding-inline: 24px;
    margin-bottom: 80px;
    .communityHeader {
      padding-inline: 16px;
      margin-bottom: 56px;
      .sectionTitle {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .communitySignUp {
      padding: 40px 20px;

      .signUpHeader {
        margin-bottom: 48px;
        .signUpBtn {
          width: 100%;
          max-width: 135px;
        }
      }

      .signUpTitle {
        font-size: 18px !important;
        margin-bottom: 8px !important;
      }

      .benefitsWrapper {
        gap: 32px;
        .benefitCard {
          width: 100%;
          .benefitIcon {
            width: 132px;
            height: 132px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .provider {
      margin-bottom: 64px;
    }
    .smScreen {
      display: block !important;
    }
    .lgScreen {
      display: none;
    }
  }
`;

export const TestimonialStyle = styled.div`
  width: 100%;
  margin-bottom: 116px;
  font-family: 'FMMFont', sans-serif !important;

  .headerWrapper {
    width: 100%;
    padding-inline: 80px;
    margin-bottom: 80px;

    .sectionTitle {
      font-size: 40px;
      font-weight: 700;
      color: ${FMMTheme.colors.grey_900};
      line-height: 110%;
      margin-bottom: 16px;
      max-width: 469px;
    }
    .sectionSubText {
      font-size: 24px;
      color: ${FMMTheme.colors.grey_700};
      line-height: 32px;
    }
  }

  .testimonialsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 44px;
  }

  .smScreen {
    display: none;
  }

  @media (max-width: 1024px) {
    margin-bottom: 80px;
    .headerWrapper {
      padding-inline: 64px;

      .sectionTitle {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 12px;
        max-width: 321px;
      }
      .sectionSubText {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  @media (max-width: 576px) {
    margin-bottom: 64px;
    .headerWrapper {
      padding-inline: 52px;

      .sectionTitle {
        font-size: 20px;
        line-height: 30px;
        max-width: 293px;
      }
      .sectionSubText {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .smScreen {
      display: block;
    }
    .lgScreen {
      display: none;
    }
  }
`;

export const RequestStyle = styled.div`
  width: 100%;
  padding-bottom: 130px;
  padding-inline: 80px;
  font-family: 'FMMFont', sans-serif !important;

  .leftSection,
  .rightSection {
    height: 580px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    padding: 42px 32px;
    z-index: 1;
    border: 1px double transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  .sectionTitle {
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
    color: ${FMMTheme.colors.grey_800};
    max-width: 300px;
    margin-bottom: 8px;
    letter-spacing: 1;
  }
  .sectionSubText {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: ${FMMTheme.colors.grey_600};
    margin-bottom: 32px;
  }
  .imageText {
    position: absolute;
    bottom: 32px;
    left: 32px;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 1px;
    color: #fff;
    font-weight: 400;
    max-width: 275px;
    letter-spacing: 0.6px;

    .italic {
      letter-spacing: -0.6px;
      font-style: italic;
      font-weight: 600;
    }
  }

  .brief,
  .permit {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .briefBtn {
    font-weight: 400;
  }

  .noiseOverlay {
    position: absolute;
    bottom: 0;
    width: 90%;
    height: 70%;
  }

  .brief {
    margin-bottom: 80px;
    z-index: 0;
    .leftSection {
      width: calc(55% - 10px);
      background-color: #ffefea;
      border-radius: 20px;
      z-index: 0;
      background-image: linear-gradient(#ffefea, #ffefea),
        radial-gradient(
          circle at right,
          rgba(255, 255, 255, 0) 45%,
          rgba(255, 182, 157, 1)
        );

      .headerWrapper {
        z-index: 4;
      }
      .noiseOverlay {
        z-index: -1;
      }
      .briefBtn {
        z-index: 5;
      }
    }

    .rightSection {
      width: calc(45% - 10px);
      background-image: url('/assets/brief/brief_section_image.jpeg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: -50px;

      .italicPlayfair {
        font-style: italic;
        font-weight: 600;
        font-family: 'Playfair Display', serif !important;
      }
    }
  }

  .permit {
    .rightSection {
      width: calc(55% - 10px);
      background-image: linear-gradient(#ece9f7, #ece9f7),
        radial-gradient(circle at left, rgba(255, 255, 255, 0) 45%, #c5bae5);
    }
    .leftSection {
      width: calc(45% - 10px);
      box-shadow: 0px -47px 28.7px 4px rgba(0, 0, 0, 0.25) inset;
      background-image: url('/assets/permit/permit_section_image.jpeg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: -96px;
    }
    .sectionTitle,
    .sectionSubText {
      max-width: 378px;
      letter-spacing: 1;
    }

    .gridImage {
      height: 70%;
    }

    .permitText {
      display: flex;
      justify-content: space-between;

      .btnWrapper {
        width: 40px;
        height: 40px;
        display: grid;
        place-content: center;
        cursor: pointer;
      }

      .btnIcon {
        width: 32px;
        height: 32px;
        color: #141b34;
      }
    }

    .permitImages {
      width: 75%;
      height: 60%;
      position: absolute;
      bottom: 0;
      right: 0;

      .permitNotification,
      .permitImg1,
      .permitImg2 {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        background-position-y: top;
        background-position-x: left;
      }

      .permitNotification {
        background-image: url('/assets/permit/permit_notification.png');
        width: 100%;
        max-width: 224px;
        height: 33px;
        top: 0;
        left: 0;
      }

      .permitImg1,
      .permitImg2 {
        border: 1px solid ${FMMTheme.colors.violet_200};
        box-shadow: -14px 2px 22.1px -7px rgba(0, 0, 0, 0.1);
        border-radius: 16px 16px 0 0;
        background-position-y: -0.5px;
        background-position-x: -0.5px;
      }

      .permitImg1 {
        background-image: url('/assets/permit/permit_1.png');
        left: 0;
        top: 42px;
        width: 70%;
      }

      .permitImg2 {
        background-image: url('/assets/permit/permit_2.png');
        top: 8px;
        left: 32%;
      }
    }

    .imageText {
      max-width: 350px;
    }
  }

  .gridImage,
  .graphicsImage {
    width: 90%;
    height: 55%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -1;
  }

  .graphicsImageWrapper {
    position: absolute;
    width: 100%;
    height: 42%;
    bottom: 0;
    left: 0;

    .graphicsImage {
      height: 100%;
      width: calc(100% - 64px);
      border: 2px solid ${FMMTheme.colors.primary_200};
      border-bottom: none;
      background-color: #fff;
      background-image: url('/assets/brief/brief_image1.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
      border-radius: 20px 20px 0 0;
      box-shadow: 0px -2.523px 43.909px 18.926px rgba(255, 206, 189, 0.3);
    }
  }

  @media (max-width: 1024px) {
    padding-inline: 64px;

    .leftSection,
    .rightSection {
      height: 420px;
      background-position: center top !important;
      padding: 32px 24px !important;
    }
    .brief {
      margin-bottom: 64px;
      .leftSection {
        .graphicsImageWrapper {
          height: 35%;
          .graphicsImage {
            width: calc(100% - 48px);
          }
        }
      }
    }

    .permit {
      .rightSection {
        .permitImages {
          height: 45%;
          width: 80%;
        }
      }
      .imageText {
        left: 24px;
        width: calc(100% - 48px);
      }
    }

    .sectionTitle {
      font-size: 24px;
      line-height: 32px;
    }
    .sectionSubText {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
      max-width: 75%;
    }
    .imageText {
      max-width: 70%;
      font-size: 24px !important;
      line-height: 30px;
    }
    .btnIcon {
      width: 24px !important;
      height: 24px !important;
    }
  }

  @media (max-width: 576px) {
    padding-inline: 24px;

    .leftSection,
    .rightSection {
      height: 320px;
      width: 100% !important;
    }
    .leftSection {
      background-position-y: -64px !important;
    }

    .brief,
    .permit {
      flex-direction: column;
    }
    .reverse {
      flex-direction: column-reverse;
    }
    .brief {
      margin-bottom: 40px;
      .leftSection {
        .graphicsImageWrapper {
          height: 25%;
        }
      }
    }

    .permit {
      .sectionSubText {
        max-width: 80%;
      }
      .rightSection {
        .permitImages {
          height: 40%;
          width: 80%;
        }
      }
    }

    .sectionTitle {
      font-size: 20px;
      line-height: 30px;
      max-width: 75%;
    }
    .sectionSubText {
      max-width: 75%;
    }
    .imageText {
      max-width: 80%;
      font-size: 20px !important;
      line-height: 30px;
    }
  }
`;

export const FooterStyle = styled.div`
  width: 100%;

  .getStartedCTA {
    width: 100%;
    height: 276px;
    background-color: #e6e7e9;
    position: relative;
    z-index: 0;
    .cta {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 2;
    }
    .ctaTitle {
      font-size: 24px;
      font-weight: 700;
      line-height: 150%;
      color: ${FMMTheme.colors.grey_900};
      margin-bottom: 8px;
      .highlight {
        color: ${FMMTheme.colors.primary_500};
      }
    }
    .ctaText {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 40px;
      color: ${FMMTheme.colors.grey_500};
    }

    .grid {
      width: 80%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 1;
    }
    .getStartedBtn {
      z-index: 2;
    }
  }
  .quickLinks {
    width: 100%;
    min-height: 488px;
    padding-block: 88px;
    background-color: ${FMMTheme.colors.secondary_500};
    color: ${FMMTheme.colors.white};
    display: flex;
    gap: 48px;

    .companyDetails {
      width: 25%;
    }

    .companyName {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      column-gap: 12px;
      margin-bottom: 12px;
    }
    .companyLogo {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .companyTitle {
      font-size: 24px;
      font-weight: 700;
      color: #fff;
    }
    .companyText {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${FMMTheme.colors.grey_300};
      margin-bottom: 28px;
    }
    .companyContact {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .companyEmail,
    .companyPhone {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
    }

    .quickLinksWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 48px;

      .quickLinkSection {
        width: fit-content;
        .sectionTitle {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 12px;
          color: #fff;
        }
        .linksWrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;
          .link {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;
            color: ${FMMTheme.colors.grey_400};
            white-space: nowrap;
            transition: color 0.3s ease-in-out;
            &:hover {
              color: ${FMMTheme.colors.grey_300};
            }
          }
        }
      }
    }

    .companyAddress {
      display: flex;
      flex-direction: column;
      gap: 32px;
      .addressTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 12px;
        color: #fff;
      }
      .addressText {
        display: flex;
        flex-direction: column;
        gap: 12px;
        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: ${FMMTheme.colors.grey_200};
        }
      }
      .smScreen {
        display: none;
      }
    }
  }

  .copyright {
    width: 100%;
    min-height: 112px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .copyrightWrapper,
    .servicedCountriesWrapper,
    .socialWrapper {
      display: flex;
      align-items: center;
    }

    .copyrightWrapper {
      gap: 8px;
      font-size: 16px;
      font-weight: 400;
      color: ${FMMTheme.colors.grey_700};
    }
    .servicedCountriesWrapper {
      gap: 32px;

      .paired {
        display: flex;
        gap: 32px;
      }
    }
  }

  .socialWrapper {
    gap: 16px;
    .socialIconWrapper {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: ${FMMTheme.colors.grey_200};
      display: grid;
      place-content: center;
      cursor: pointer;
    }

    .socialIcon {
      width: 20px;
      height: 20px;
      color: #1d2939;
    }
  }

  .servicedCountry {
    display: flex;
    align-items: center;
    gap: 12px;
    .servicedCountryFlag {
      width: 28px;
      height: 21px;
    }
    .servicedCountryText {
      font-size: 14px;
      color: ${FMMTheme.colors.grey_700};
      font-weight: 400;
      white-space: nowrap;
      margin: 0;
    }
  }

  .getStartedCTA,
  .quickLinks,
  .copyright {
    padding-inline: 80px;
  }

  @media (max-width: 1024px) {
    .getStartedCTA,
    .quickLinks,
    .copyright {
      padding-inline: 64px;
    }
    .quickLinks {
      flex-direction: column;
      padding-block: 64px;

      .companyName {
        flex-direction: row;
        align-items: center;
      }
      .companyDetails {
        width: 100%;
        max-width: 300px;
      }

      .companyTitle {
        font-size: 20px;
        line-height: 1;
      }
    }
    .lgScreen {
      display: none !important;
    }
    .smScreen {
      display: flex !important;
    }
    .copyright {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center !important;
      gap: 32px;
      padding-block: 32px;
      align-items: start;
    }
  }
  @media (max-width: 576px) {
    .getStartedCTA,
    .quickLinks,
    .copyright {
      padding-inline: 24px;
    }
    .servicedCountriesWrapper {
      flex-wrap: wrap;
      gap: 24px;
    }
    .copyright {
      padding-block: 42px;
      align-items: flex-start !important;
    }
    .ctaText {
      max-width: 300px;
      margin-bottom: 24px !important;
    }
    .ctaTitle {
      font-size: 20px !important;
      max-width: 75%;
    }
  }
`;
