import { createSlice } from '@reduxjs/toolkit';
import { IBooking } from '../../utilities';
import { CAST } from '../../utilities/dataHelper/hiring';
import { Get } from '../../utilities/useApi';
import { CastBook } from './castBookingSlice';


const castSlice = createSlice({
  name: 'cast',
  initialState: {
    cast: [] as CAST[],
    selectedCast: {} as CAST,
    bookings: [] as IBooking[],
    cart: [] as CastBook[],
  },
  reducers: {
    setCast: (state, action) => {
      state.cast = action.payload;
    },
    selectedCast: (state, action) => {
      state.selectedCast = action.payload;
    },
    addToCart: (state, action: { payload: CastBook }) => {
      if (!state.cart) state.cart = [];
      const currentCast = state.cart.find(castInCart => castInCart.castId === action.payload.castId);
      if (currentCast) {
        state.cart = state.cart.map(castInCart => {
          if (castInCart.castId === currentCast.castId) return action.payload;
          return castInCart;
        });
      } else {
        state.cart.push(action.payload);
      }
    },
    addBooking: (state, action) => {
      if (!state.bookings) state.bookings = [];
      state.bookings.push(action.payload);
    },
    emptyBooking: (state) => {
      state.bookings = [];
    },
    default: (state) => {
      return {
        ...state,
      };
    },
    resetState: (state) => {
      state = {
        cast: [] as CAST[],
        selectedCast: {} as CAST,
        bookings: [] as IBooking[],
        cart: [] as CastBook[],
      };
      return state;
    },
  },
});

export const castReducer = castSlice.reducer;

export const { setCast, selectedCast, addBooking, emptyBooking, addToCart } =
  castSlice.actions;

export const setSelectedCast = (cast: CAST) => {
  return (dispatch: any) => {
    dispatch(selectedCast(cast));
  };
};

export const fetchCast = (token: string | null) => {
  return (dispatch: any) => {
    Get({
      url: '/cast/fetch-all-verified',
      token,
    })
      .then((res) => {
        dispatch(setCast(res.cast));
        // .log(res.cast);
      })
      .catch((error: any) => {
        throw error;
      });
  };
};
