import { notification } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';
import router from 'next/router';
import { USER_ROLES } from '.';
import { stagingUrls } from './dataHelper/apiUrl';

const client = axios.create({
  baseURL: stagingUrls.apiBaseUrl,
});

export const api = async (options: AxiosRequestConfig<any>) => {
  const onSuccess = (response: IResponse) => {
    return response.data;
  };

  const onError = (error: any) => {
    if (error.response && error.response.status === 401) {
      notification.open({
        message: 'Session expired',
        description: 'Please login',
      });
      router.push('/login');
    }
    if (error.message === 'Network Error') return Promise.reject(error.message);
    return Promise.reject(error.response.data || error.message);
  };

  try {
    const response = await client(options);
    return onSuccess(response.data);
  } catch (error) {
    return onError(error);
  }
};

export const Post = async ({ url, data, token }: UseApiOptions) =>
  api({
    method: 'POST',
    url,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const Get = async ({ url, token }: UseApiOptions) =>
  api({
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

export const Patch = async ({ url, data, token }: UseApiOptions) =>
  api({
    method: 'PATCH',
    url,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const Delete = async ({ url, data, token }: UseApiOptions) =>
  api({
    method: 'DELETE',
    url,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export interface UseApiOptions {
  url: string;
  data?: any;
  token?: string | null;
}

export interface ResponseStatus {
  status: 'error' | 'success';
}

export interface IResponse {
  status: number;
  statusCode: number;
  message: string;
  data: IResponseData;
}

export interface IResponseData {
  [key: string]: string | number | boolean | Array<any> | any;
}

export function useLoginRouter({
  role,
  accessToken,
}: {
  role: string;
  accessToken: string | undefined | null;
}) {
  if (role === USER_ROLES.SCOUT) {
    return router.push(
      process.env.NEXT_PUBLIC_SCOUT_URL + '?userId=' + accessToken,
    );
  } else if (role === USER_ROLES.OWNER) {
    return router.push(
      `${process.env.NEXT_PUBLIC_CLIENT_URL}/location` +
        '?userId=' +
        accessToken,
    );
  } else if (role === USER_ROLES.SERVICE_PROVIDER) {
    return router.push(
      `${process.env.NEXT_PUBLIC_OWNER_URL}/contract` +
        '?userId=' +
        accessToken,
    );
  } else if (role === USER_ROLES.CAST) {
    return router.push(
      process.env.NEXT_PUBLIC_CAST_URL + '?userId=' + accessToken,
    );
  } else if (role === USER_ROLES.CREW) {
    return router.push(
      process.env.NEXT_PUBLIC_CREW_URL + '?userId=' + accessToken,
    );
  } else if (router.pathname === '/login' || '/SignUp' || '/verify-account') {
    const callbackUrl = localStorage.getItem('callbackUrl');
    return router.replace(callbackUrl ?? '/');
  } else {
    router.reload();
  }
}
