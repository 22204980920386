import { createSlice } from '@reduxjs/toolkit';
import { CAST } from '../../utilities/dataHelper/hiring';

const CastBooking = createSlice({
  name: 'castBooking',
  initialState: {} as CastBook | null,
  
  reducers: {
    booking: (state, action: { payload: CastBook }) => {
      state = action.payload;
    },
    emptyCastState: (state) => {
      state = null;
      return state;
    },
  },
});

export const castBookingReducer = CastBooking.reducer;
export const { booking, emptyCastState } = CastBooking.actions;

export interface CastBook {
  requestType?: string
  userId?: string
  castId: string
  productionCompanyName: string
  productionType: string
  productionDuration: number
  shootLocations: string
  commencementDate: string
  budgetForCast: number
  cast: CAST
} 