import { createSlice } from '@reduxjs/toolkit';
import { IRENTAL } from '../../utilities/dataHelper/equipment';



const productSlice = createSlice({
  name: 'product',
  initialState: [] as IRENTAL[],
  reducers: {
    addToCart: (state: IRENTAL[], action) => {
      const itemExist = state.find((item) => item.id === action.payload.id);
      if (itemExist) {
        state = state;
      } else {
        state.push({ ...action.payload, quantity: 1 });
      }
    },

    removeFromCart: (state: IRENTAL[], action) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      state.splice(index, 1);
    },
    
    
  },
});

export const productReducer = productSlice.reducer;

export const {
  addToCart,
  removeFromCart,
  
 
} = productSlice.actions;

