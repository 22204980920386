import 'antd/dist/reset.css';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import LayoutComponent from '../components/layout';
import store from '../redux/store';
import '../styles/globals.css';
import * as ga from '../lib/ga';
import { notification } from 'antd';
import { hugeiconsLicense } from '@hugeicons/react-pro';

hugeiconsLicense(process.env.NEXT_PUBLIC_HUGEICONS_LICENSE || '');

let persistor = persistStore(store);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const authPathArray = ['/login', '/signup', '/verify-OTP', '/new-password', '/password-reset', '/verify-account', '/change-email'];

  const NComponent = () => authPathArray.includes(router.pathname) ? (
    <Component {...pageProps} />
  ) : (
    <LayoutComponent>
      <Component {...pageProps} />
    </LayoutComponent>
  );

  notification.config({
    maxCount: 2,
    duration: 3,
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div>
          <Head>
            <meta name="description" content="Filmmakers Mart is a comprehensive solution for all your filming needs - from scouting ideal locations to managing logistics and hiring talent." />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon.ico" />
            <title>Filming Locations, Logistics, and Hiring needs in one place</title>
          </Head>
          <NComponent />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default MyApp;
