import styled from 'styled-components';
import { FMMTheme } from '../theme';

export const NavBarStyle = styled.div`
  width: 100%;
  height: 96px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  gap: 40px;
  padding-inline: 80px;

  .navSection:first-child {
    min-width: 95px;
  }

  .navSection {
    width: calc(100% / 3);
  }

  .navSection:first-child {
    .logo {
      cursor: pointer;
      background-position-x: left;
      /* width: 100px; */
    }
  }

  .navSection:nth-child(2) {
    min-width: 300px;
    display: flex;
    gap: 32px;
    justify-content: center;
    align-items: center;

    .link {
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: ${FMMTheme.colors.grey_900};
      transition: color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
      &:hover {
        color: ${FMMTheme.colors.primary_500};
        font-weight: 700;
      }
    }
    .active {
      color: ${FMMTheme.colors.primary_500};
      font-weight: 700;
    }
  }

  .cta {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;

    .loginBtn {
      padding-inline: 20px;
      font-size: ${FMMTheme.fontSize.base};
      font-weight: ${FMMTheme.fontWeight.medium};
      color: ${FMMTheme.colors.teal_500};
      cursor: pointer;
      transition: color 0.3s ease-in-out;
      white-space: nowrap;
      &:hover {
        color: ${FMMTheme.colors.primary_500};
      }
    }
  }

  .smallMenu {
    display: none;
    position: relative;
    height: 24px;
    width: 24px;
    cursor: pointer;
    border-radius: 4px;
    border: none;

    .bar1,
    .bar2,
    .bar3 {
      position: absolute;
      height: 2px;
      border-radius: 1px;
      background-color: ${FMMTheme.colors.grey_900};
    }

    .bar1,
    .bar3 {
      width: 90%;
    }
    .bar2 {
      width: 70%;
    }
  }

  .logoWrapper {
    position: relative;
    height: 38px;
    width: 89px;
    display: flex;
    justify-content: flex-start;
  }

  @media (max-width: 1024px) {
    padding-inline: 64px;
    justify-content: space-between;
    .navSection:nth-child(2),
    .navSection:last-child,
    .cta {
      display: none;
    }
    .smallMenu {
      display: block;
    }
    .navSection {
      min-width: fit-content;
    }
  }

  @media screen and (max-width: 576px) {
    padding-inline: 24px;
    .navSection:first-child {
      min-width: 75px;
    }

    .logoWrapper {
      height: 28px;
      width: 66px;
    }
  }
`;

export const MobileMenuStyle = styled.div<{ show: boolean }>`
  width: 100vw;
  max-width: 1024px;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  height: ${(props) => (props.show ? 'auto' : '0px')};

  .mobileMenu {
    width: 100vw;
    min-height: 80px;
    background-color: #fff;
    border-top: 1px solid ${FMMTheme.colors.grey_200};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 64px;
    gap: 40px;

    .navSection:first-child {
      min-width: 300px;
      display: flex;
      gap: 24px;
      justify-content: center;
      align-items: center;

      .link {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        color: ${FMMTheme.colors.grey_900};
        transition: color 0.3s ease-in-out;
        &:hover {
          color: ${FMMTheme.colors.primary_500};
        }
      }
    }

    .cta {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 8px;

      .loginBtn {
        padding-inline: 20px;
        font-size: ${FMMTheme.fontSize.base};
        font-weight: ${FMMTheme.fontWeight.medium};
        color: ${FMMTheme.colors.teal_500};
        cursor: pointer;
        transition: color 0.3s ease-in-out;
        white-space: nowrap;
        &:hover {
          color: ${FMMTheme.colors.primary_500};
        }
      }
    }

    @media (max-width: 576px) {
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      .navSection {
        width: 100% !important;
        flex-direction: column;
        align-items: flex-start !important;
        gap: 32px !important;
        .link {
          width: fit-content;
        }
      }

      .cta {
        gap: 24px !important;
      }

      .productionBtn {
        max-width: 100%;
        height: 48px !important;
      }

      .loginBtn {
        width: 100%;
        border: 1px solid ${FMMTheme.colors.teal_500};
        height: 48px;
        border-radius: 8px;
        display: grid;
        place-content: center;
      }
    }
  }

  @media (max-width: 576px) {
    height: ${(props) => (props.show ? 'calc(100vh - 96px)' : '0px')};
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const ProfileMenuStyle = styled.div`
  height: 48px;
  z-index: 999000;

  .framerClass {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    width: fit-content;
  }

  .profileWrapper {
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 2px 4px;
    border-radius: 0.5rem;
    color: ${FMMTheme.colors.grey_600};
    border: none;
    .userIcon {
      color: ${FMMTheme.colors.grey_600};
      font-size: 1.2rem;
      min-width: 20px;
    }
    .arrowIcon {
      min-width: 18px;
    }
    &:hover {
      color: ${FMMTheme.colors.grey_900};
      .userIcon {
        color: ${FMMTheme.colors.grey_900};
        font-size: 1.2rem;
      }
    }
  }

  .profileIcon {
    font-weight: 500;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .menuOptions {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 118px;
    padding: 0.25rem;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }

  .menuOption {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    font-weight: medium;
    white-space: nowrap;
    border-radius: 0.5rem;
    color: #212529;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: ${FMMTheme.colors.grey_100};
    &:hover {
      background-color: ${FMMTheme.colors.primary_50};
      color: ${FMMTheme.colors.primary_500};
    }
    cursor: pointer;
  }

  .marketMenu {
    color: #363636;
    font-size: 16px;
    font-weight: 400;
    background-color: transparent;
    cursor: pointer;
    transition-property: color, font-weight;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    border: none;
    outline: none;
    padding-block: 24px;
  }

  .marketMenu:hover {
    color: ${FMMTheme.colors.primary_500};
    font-weight: 700;
  }

  .active {
    color: ${FMMTheme.colors.primary_500};
    font-weight: 700;
  }
`;
