import { createSlice } from '@reduxjs/toolkit';
import { IRENTAL } from '../../utilities/dataHelper/equipment';
import { Get } from '../../utilities/useApi';

const equipmentSlice = createSlice({
  name: 'rentals',
  initialState: { rentals: [] as IRENTAL[], selectedRental: {} as IRENTAL },
  reducers: {
    setRentals: (state, action) => {
      state.rentals = action.payload;
    },
    setSelectedRental: (state, action) => {
      state.selectedRental = action.payload;
    },
    resetRentals: (state) => {
      state = { rentals: [] as IRENTAL[], selectedRental: {} as IRENTAL };
      return state;
    },
  },
});

export const rentalsReducer = equipmentSlice.reducer;

export const { setRentals, setSelectedRental, resetRentals } =
  equipmentSlice.actions;

export const setSelectedLocation = (equipment: IRENTAL) => {
  return (dispatch: any) => {
    dispatch(setSelectedRental(equipment));
  };
};

export const fetchEquipment = (token: string | null) => {
  return (dispatch: any) => {
    Get({
      url: '/equipments/rentals/fetch-all-verified?page=1',
      token,
    })
      .then((res) => {
        dispatch(setRentals(res.rentals));
        // .log(res)
      })
      .catch((error: any) => {
        throw error;
      });
  };
};
