/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import { AccommodationFormValues } from '../../pages/marketplace/logistics/accommodation/[booking]';


const accommodationCart = createSlice({
  name: 'accommodationCart',
  initialState: { temp: [] as AccommodationFormValues[], cart: [] as AccommodationFormValues[] },
  reducers: {
    removeFromCart: (state, action:  { payload: AccommodationFormValues }) => {
      
      state = {
        cart: state.cart.filter((item) => item.accommodationId !== action.payload.accommodationId),
        temp: state.temp.filter((item) => item.accommodationId !== action.payload.accommodationId),
      };
      return state;
    },
    addToCart: (state, action: { payload: AccommodationFormValues }) => {
      const current = state.temp.find(acc => acc.accommodationId === action.payload.accommodationId);
      if (!current) {
        state.temp.push(action.payload);
        
      }
      state.temp = state.temp.map(acc => {
        if ( acc.accommodationId === action.payload.accommodationId) return action.payload;
        return acc;
      });
    },
    modifyCart: (state) => {
      state.cart = state.temp;
      state.cart = state.cart.map(item => {
        item.accommodationRequired = item.accommodationRequired.filter(room => room.cost > 0);
        return item;
      });
    },
    emptyCart: (state) => {
      state.cart = [];
      state.temp = [];
    },
  },
  
});


export const accommodationCartReducer = accommodationCart.reducer;

export const {
  addToCart, modifyCart, emptyCart,
  removeFromCart,
} = accommodationCart.actions;
