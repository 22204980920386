/*
 * component: FlexibleDiv
 * author: Tomisin
 * Date: May 16th 2023
 * Recce-Solutions FlexibleDiv and Gridable component
 *  use props to customize where rendered
 */

import styled from 'styled-components';

interface StyleProps {

  display?: string
  justifyContent?: string
  alignItems?: string
  width?: string | number
  height?: string | number
  flexWrap?: string
  flexDir?: string
  alignContent?: string
  maxWidth?: string
  margin?: string
  gap?: string
  flexGrow?: string
  order?: string
}

export const FlexibleDiv = styled('div')<StyleProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  flex-wrap: ${({ flexWrap }) => flexWrap ?? 'wrap'};
  flex-direction: ${({ flexDir }) => flexDir ?? 'row'};
  width: ${({ width }) => width ?? 'max-content'};
  height: ${({ height }) => height ?? 'max-content'};
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  gap: ${({ gap }) => gap ?? '0'};
  flex-grow: ${({ flexGrow }) => flexGrow ?? '0'};
  order: ${({ order }) => order ?? '0'};
`;

// Flexible box section element
export const Section = styled('section')<StyleProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  flex-wrap: ${({ flexWrap }) => flexWrap ?? 'wrap'};
  flex-direction: ${({ flexDir }) => flexDir ?? 'row'};
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? 'max-content'};
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  align-content: ${({ alignContent }) => alignContent ?? 'center'};
  gap: ${({ gap }) => gap ?? '0'};
  flex-grow: ${({ flexGrow }) => flexGrow ?? '0'};

`;

