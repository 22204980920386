import React from 'react';
import { Button, Typography } from 'antd';
import { AiOutlineArrowRight as Icon } from 'react-icons/ai';
import { FlexibleDiv } from '../box';
import { ThemeProvider } from 'styled-components';
import { FmmTheme } from '../theme';
import { ButtonWrapper, StyledButton } from './style';
import { MktButtonProps } from './interface';
import { MktButtonStyle } from './style';

interface But {
  children: React.ReactNode
  className?: string;
  action?: Function;
}
export function ButtonText({ children, action }: But) {
  const { Text } = Typography;
  return (
    <ButtonWrapper>
      <Button type="text" onClick={() => {
        return action && action();
      }}>
        <FlexibleDiv justifyContent='space-evenly' alignItems='center' className='btn'>
          <Text>{children}</Text>
          <Icon className="icon" />
        </FlexibleDiv>
      </Button>
    </ButtonWrapper>
  );
}

export function FmmButton({
  onClick,
  text,
  className,
  width,
  height,
  border,
  borderRadius,
  backgroundColor,
  fontSize,
  color,
  maxWidth,
  icon,
  icon2,
}: {
  onClick?: React.MouseEventHandler;
  text: string;
  className?: string;
  width?: string;
  height?: string;
  border?: string;
  borderRadius?: string;
  backgroundColor?: string;
  fontSize?: string;
  color?: string;
  maxWidth?: string;
  icon?: React.ReactNode;
  icon2?: React.ReactNode;
  buttonType?: any
}): JSX.Element {
  return (
    <ThemeProvider theme={FmmTheme}>
      <StyledButton
        onClick={onClick}
        width={width}
        height={height}
        border={border}
        color={color}
        className={className}
        borderRadius={borderRadius}
        backgroundColor={backgroundColor}
        fontSize={fontSize}
        maxWidth={maxWidth}
      >
        <div className="buttonWithIcon">
          {icon && <div className="icon">
            {icon}
          </div>}
          <>{text}</>
          {icon2 && <div className="icon">
            {icon2}
          </div>}
        </div>
      </StyledButton>
    </ThemeProvider>
  );
}


function MktButton({ label, $type, $maxWidth, $disabled, $width, $color, $height, $className, icon, onClick, suffixIcon, type }: MktButtonProps) {
  return (
    <MktButtonStyle
      $type={$type}
      $width={$width}
      $color={$color}
      $maxWidth={$maxWidth}
      disabled={$disabled}
      $height={$height}
      className={$className}
      onClick={onClick}
      type={type}
    >
      {icon}
      {label}
      {suffixIcon}
    </MktButtonStyle>
  );
}

export default MktButton;