import { createSlice } from '@reduxjs/toolkit';
import { ITRANSPORT } from '../../utilities/dataHelper/transport';
import { Get } from '../../utilities/useApi';

const transportationSlice = createSlice({
  name: 'transportation',
  initialState: { transport: [] as ITRANSPORT[], selectedTransport: {} as ITRANSPORT },
  reducers: {
    setTransport: (state, action) => {
      state.transport = action.payload;
    },
    setSelectedTransport: (state, action) => {
      state.selectedTransport = action.payload;
    },
    resetTransport: (state) => {
      state = { transport: [] as ITRANSPORT[], selectedTransport: {} as ITRANSPORT };
      return state;
    },
  },
});

export const transportationReducer = transportationSlice.reducer;

export const {
  setTransport,
  setSelectedTransport, resetTransport,
} = transportationSlice.actions;

export const setSelectedTransportation = (transport: ITRANSPORT) => {
  return (dispatch:any) => {
    dispatch(setSelectedTransport(transport));
  };  
      
};
  
  
export const fetchTransport = (token: string | null) => {
  return (dispatch: any) => {
    Get({
      url: '/transport/fetch-all-verified?page=1', token,
    })
      .then((res) => {
        dispatch(setTransport(res.transport));
      })
      .catch((error: any) => {
        throw (error);
      });
  };
};
  