import React, { ReactNode } from 'react';
import { FooterStyle } from './style';
import MktButton from '../button';
import FooterGrid from '@/public/assets/svg/footer_grid.svg';
import Copyright from '@/public/assets/svg/copyright.svg';
import NigeriaFlag from '@/public/assets/svg/nigeria.svg';
import GhanaFlag from '@/public/assets/svg/ghana.svg';
import KenyaFlag from '@/public/assets/svg/kenya.svg';
import SouthAfricaFlag from '@/public/assets/svg/south_africa.svg';
import FMMLogo from '@/public/assets/svg/logo.svg';
import Link from 'next/link';
import InstagramIcon from '@/public/assets/svg/instagram.svg';
import TwitterIcon from '@/public/assets/svg/twitter.svg';
import FacebookIcon from '@/public/assets/svg/facebook.svg';
import LinkedInIcon from '@/public/assets/svg/linkedIn.svg';
import { motion } from 'framer-motion';

const ServicedCountry = ({ country, flag }: { country: string, flag: ReactNode }) => {
  return (
    <div className="servicedCountry">
      {flag}
      <p className="servicedCountryText">{country}</p>
    </div>
  );
};

function QuickLinkSection({ sectionName, links }: { sectionName: string, links: { name: string, link: string }[] }) {
  return (
    <div className='quickLinkSection'>
      <h3 className="sectionTitle">{sectionName}</h3>
      <div className='linksWrapper'>
        {links.map((link: { name: string, link: string }) => <Link key={link.name} href={link.link} className='link'>{link.name}</Link>)}
      </div>
    </div>
  );
}

function Footer() {
  const services01 = [{ name: 'Tent', link: '/marketplace/logistics/welfare/tent' }, { name: 'Trailer', link: '/marketplace/logistics/welfare/trailer' }, { name: 'Welfare', link: '/marketplace/logistics/welfare' }, { name: 'Cast', link: '/marketplace/hiring/cast' }, { name: 'Crew', link: '/marketplace/hiring/crew' }];
  const services02 = [{ name: 'Equipments', link: '/marketplace/logistics/equipment' }, { name: 'Location', link: '/marketplace' }, { name: 'Accommodation', link: '/marketplace/logistics/accommodation' }, { name: 'Mobile Toilets', link: '/marketplace/logistics/welfare/mobile-toilet' }, { name: 'Transportation', link: '/marketplace/logistics/transportation' }];
  const company = [{ name: 'About Us', link: '/about-us' }, { name: 'Testimonials', link: '/#testimonials' }, { name: 'Contact Us', link: '/#' }, { name: 'Pricing', link: '/#' }, { name: 'Terms & Privacy Policy', link: '/privacy-policy' }];
  const resources = [{ name: 'FAQs', link: '/#' }, { name: 'Service Providers', link: 'https://providers.filmmakersmart.com/' }, { name: 'Brief', link: '/brief' }, { name: 'Permit', link: '/permit' }];
  return (
    <motion.div initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 0.75, delay: 0.5 } }} viewport={{ once: true }} className='w-full'>
      <FooterStyle>
        <div className="getStartedCTA">
          <FooterGrid className='grid' />
          <div className="cta">
            <h3 className="ctaTitle"><span className='highlight'>Get started</span> with Filmmakers Mart today</h3>
            <p className="ctaText">Search for a service, Book and have a stress-free production.</p>
            <MktButton label='Get Started for free' $type='primary' $width='197px' $height={48} $className='getStartedBtn' onClick={() => window.location.href = '/marketplace'} />
          </div>
        </div>
        <div className="quickLinks">
          <div className="companyDetails">
            <div className='companyNameWrapper'>
              <div className="companyName">
                <FMMLogo className='companyLogo' onClick={() => window.scrollTo(0, 0)} />
                <h3 className="companyTitle">Filmmakers Mart</h3>
              </div>
              <p className="companyText">The number one leading marketplace for everything production.</p>
            </div>
            <div className="companyContact lgScreen">
              <a href="mailto:info@filmmakersmart.com" className="companyEmail">info@filmmakersmart.com</a>
              <a href="tel:+234 904 168 3514" className="companyPhone">Call +234 904 168 3514</a>
            </div>
          </div>
          <div className="quickLinksWrapper">
            <QuickLinkSection sectionName='Services' links={services01} />
            <QuickLinkSection sectionName='Services' links={services02} />
            <QuickLinkSection sectionName='Company' links={company} />
            <QuickLinkSection sectionName='Resources' links={resources} />
          </div>
          <div className="companyAddress">
            <div className="companyLocation">
              <h3 className="addressTitle">Location</h3>
              <p className="addressText"><span>4th floor, MJS House,</span><span>366 Murtala Mohammed Way,</span><span>Yaba, Lagos</span>
              </p>
            </div>
            <div className="companyContact smScreen">
              <a href="mailto:info@filmmakersmart.com" className="companyEmail">info@filmmakersmart.com</a>
              <a href="tel:+234 904 168 3514" className="companyPhone">Call +234 904 168 3514</a>
            </div>
            <div className="socialWrapper smScreen">
              <Link href={'http://instagram.com/filmmakers_mart'} target='_blank' className='socialIconWrapper'>
                <InstagramIcon className='socialIcon' />
              </Link>
              <Link href={'https://www.facebook.com/filmmakersmart/'} target='_blank' className='socialIconWrapper'>
                <FacebookIcon className='socialIcon' />
              </Link>
              <Link href={'http://twitter.com/filmmakers_mart'} target='_blank' className='socialIconWrapper'>
                <TwitterIcon className='socialIcon' />
              </Link>
              <Link href={'https://www.linkedin.com/company/recce-solutions'} target='_blank' className='socialIconWrapper'>
                <LinkedInIcon className='socialIcon' />
              </Link>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className='copyrightWrapper'>
            <Copyright className='copyrightIcon' />
            <span>
              {new Date().getFullYear()} Filmmakers Mart. All rights reserved.
            </span>
          </div>
          <div className="servicedCountriesWrapper">
            <div className="paired">
              <ServicedCountry
                country='Nigeria'
                flag={<NigeriaFlag className='servicedCountryFlag' />}
              />
              <ServicedCountry
                country='Ghana'
                flag={<GhanaFlag className='servicedCountryFlag' />}
              />
            </div>
            <div className="paired">
              <ServicedCountry
                country='Kenya'
                flag={<KenyaFlag className='servicedCountryFlag' />}
              />
              <ServicedCountry
                country='South Africa'
                flag={<SouthAfricaFlag className='servicedCountryFlag' />}
              />
            </div>
          </div>
          <div className="socialWrapper lgScreen">
            <Link href={'http://instagram.com/filmmakers_mart'} target='_blank' className='socialIconWrapper'>
              <InstagramIcon className='socialIcon' />
            </Link>
            <Link href={'https://www.facebook.com/filmmakersmart/'} target='_blank' className='socialIconWrapper'>
              <FacebookIcon className='socialIcon' />
            </Link>
            <Link href={'http://twitter.com/filmmakers_mart'} target='_blank' className='socialIconWrapper'>
              <TwitterIcon className='socialIcon' />
            </Link>
            <Link href={'https://www.linkedin.com/company/recce-solutions'} target='_blank' className='socialIconWrapper'>
              <LinkedInIcon className='socialIcon' />
            </Link>
          </div>
        </div>
      </FooterStyle>
    </motion.div>
  );
}

export default Footer;
