import { FmmTheme } from '../theme';
import styled from 'styled-components';
import { FMMTheme } from '../theme';

export const ButtonWrapper = styled.div`
  font-family: 'FMMFont', sans-serif !important;
  .ant-btn {
    height: 6vh;
    min-width: 100px;
    transition: all 0.2s linear;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background: #ff6029;
    font-size: 2vh;
    padding: 0.3vh 2vh;
    color: #fff;
    .btn {
      > svg {
        margin-left: 15px;
        margin-right: 5px;
        font-size: 2vh;
        transition: all 0.4s ease-out;
      }
    }
  }
  .ant-btn:hover > svg {
    font-size: 1.2em;
    transform: translateX(5px);
  }
  .ant-btn:hover {
    transform: translateY(-2px);
  }
  .ant-typography {
    span {
      color: #fff;
      border-radius: 5px;
      letter-spacing: 1px;
      font-weight: 600;
      line-height: 22px;
      font-size: 2.4vh;
    }
  }
`;

interface IFmmButton {
  width?: string;
  height?: string;
  maxWidth?: string;
  minWidth?: string;
  border?: string;
  borderRadius?: string;
  backgroundColor?: string;
  padding?: string;
  margin?: string;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  cursor?: string;
}
export const StyledButton = styled('button')<IFmmButton>`
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '100%'};
  font-family: 'FMMFont', sans-serif !important;
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  border: ${({ border }) => border ?? '1px solid transparent'};
  border-radius: ${({ borderRadius }) =>
    borderRadius ?? FmmTheme.borderRadius.button};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? FmmTheme.colors.primary};
  padding: ${({ padding }) => padding ?? '0'};
  margin: ${({ margin }) => margin ?? '0'};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'bold'};
  color: ${({ color }) => color ?? FmmTheme.colors.text.white};
  cursor: ${({ cursor }) => cursor ?? 'pointer'};
  .buttonWithIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const BtnBackground = (type: string) => {
  switch (type) {
    case 'primary':
      return FMMTheme.colors.primary_500;
    case 'secondary':
      return FMMTheme.colors.teal_500;
    case 'tertiary':
      return FMMTheme.colors.white;
    default:
      return '#fff';
  }
};

const BtnBoxShow = (type: string) => {
  switch (type) {
    case 'primary':
      return FMMTheme.colors.primary_100;
    case 'secondary':
      return FMMTheme.colors.teal_100;
    case 'tertiary':
      return FMMTheme.colors.grey_200;
    default:
      return '#fff';
  }
};

export const MktButtonStyle = styled.button<{
  $type?: string;
  $width?: string;
  $color?: string;
  $maxWidth?: number;
  $height?: number;
}>`
  height: ${(props) => props.$height || 48}px;
  width: ${(props) => props.$width || '100%'};
  max-width: ${(props) => props.$maxWidth || 300}px;
  background-color: ${(props) => BtnBackground(props.$type || '')};
  border-radius: 12px;
  color: ${(props) => props.$color || FMMTheme.colors.white};
  font-size: ${FMMTheme.fontSize.base};
  font-weight: ${FMMTheme.fontWeight.bold};
  cursor: pointer;
  box-shadow: 0px 0px 0px 2px ${(props) => BtnBoxShow(props.$type || '')};
  letter-spacing: 0.5px;
  padding: 0 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  font-family: 'FMMFont', sans-serif !important;
`;
