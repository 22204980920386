import { createSlice } from '@reduxjs/toolkit';
import { IBooking, IInspection } from '../../utilities';
import { ILOCATION } from '../../utilities';
import { Get } from '../../utilities/useApi';




const locationSlice = createSlice({
  name: 'locations',
  initialState: { locations: [] as ILOCATION[], selectedLocation: {} as ILOCATION, bookings: [] as IBooking[], inspections: [] as IInspection[], currentInspection: {} as IInspection },
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    selectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    addBooking: (state, action) => {
      if (!state.bookings) state.bookings = [];
      state.bookings.push(action.payload);
    },
    emptyBooking: (state) => {
      state.bookings = [];
    },
    addInspection: (state, action: { payload: IInspection }) => {
      if (!state.inspections) state.inspections = [];
      state.inspections.push(action.payload);
    },
    modifyInspection: (state, action: { payload: IInspection }) => {
      const current = state.inspections.find(insp => insp.id === action.payload.id);
      if (current === undefined) {
        if (!state.inspections) state.inspections = [];
        state.inspections.push(action.payload);
      }
      if (current) {
        current.date = action.payload.date;
        current.time = action.payload.time;
        const index = state.inspections.findIndex((item) => item.id === action.payload.id);
        state.inspections[index] = current;
      }
    },
    removeInspection: (state, action: { payload: IInspection }) => {
      const index = state.inspections.findIndex((item) => item.id === action.payload.id);
      state.inspections.splice(index, 1);
    },
    emptyInspection: (state) => {
      state.inspections = [];
    },
    default: (state) => {
      return {
        ...state,
      };
    },
    resetState: (state) => {
      state = { locations: [] as ILOCATION[], selectedLocation: {} as ILOCATION, bookings: [] as IBooking[], inspections: [] as IInspection[], currentInspection: {} as IInspection };
      return state;
    },
  },
});

export const locationReducer = locationSlice.reducer;

export const {
  setLocations,
  selectedLocation,
  addBooking,
  emptyBooking,
  addInspection,
  modifyInspection,
  removeInspection,
  emptyInspection,
} = locationSlice.actions;


export const setSelectedLocation = (location:ILOCATION) => {
  return (dispatch:any) => {
    dispatch(selectedLocation(location));
  };  
    
};


export const fetchLocations = (token: string | null) => {
  return (dispatch: any) => {
    Get({
      url: '/locationservice/locations?page=2', token,
    })
      .then((res) => {
        dispatch(setLocations(res.locations));

      })
      .catch((error: any) => {
        throw (error);
      });
  };
};
