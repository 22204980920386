import { Layout } from 'antd';
import React from 'react';
import { LayoutWrapper } from '../layout/style';
import Footer from '../landing/footer';
import NavBar from '../header';

interface LayoutChildren {
  children: React.ReactNode;
}

const LayoutComponent = ({ children }: LayoutChildren) => {
  const { Content } = Layout;
  return (
    <LayoutWrapper>
      <NavBar />
      <Content>{children}</Content>
      <Footer />
    </LayoutWrapper>
  );
};

export default LayoutComponent;
