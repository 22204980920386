import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IBooking } from '../../utilities';
import { CREW } from '../../utilities/dataHelper/hiring';
import { Get } from '../../utilities/useApi';

const crewSlice = createSlice({
  name: 'crew',
  initialState: {
    crew: [] as CREW[],
    selectedCrew: {} as CREW,
    bookings: [] as IBooking[],
  },
  reducers: {
    setCrew: (state, action: { payload: CREW[] }) => {
      state.crew = action.payload;
    },
    selectedCrew: (state, action: { payload: CREW }) => {
      state.selectedCrew = action.payload;
    },
    
   
    addBooking: (state, action: { payload: IBooking }) => {
      if (!state.bookings) state.bookings = [];
      state.bookings.push(action.payload);
    },
    emptyBooking: (state) => {
      state.bookings = [];
    },
   
    default: (state) => {
      return {
        ...state,
      };
    },
    // dal
  },
});

export const crewReducer = crewSlice.reducer;

export const {
  setCrew,
  selectedCrew,
  addBooking,
  emptyBooking,
  
} = crewSlice.actions;

export const setSelectedCrew = (crew: CREW) => {
  return (dispatch: Dispatch) => {
    dispatch(selectedCrew(crew));
  };
};

export const fetchCrew = (token: string | null) => {
  return (dispatch: Dispatch) => {
    Get({
      url: '/crew/fetch-all-verified',
      token,
    })
      .then((res) => {
        dispatch(setCrew(res.crew));
      })
      .catch((error) => {
        throw error;
      });
  };
};
