import { createSlice } from '@reduxjs/toolkit';
import { IBooking } from '../../utilities';
import { IWELFARE } from '../../utilities/dataHelper/welfare';
import { Get } from '../../utilities/useApi';

const welfareSlice = createSlice({
  name: 'welfare',
  initialState: {
    welfare: [] as IWELFARE[],
    selectedWelfare: {} as IWELFARE,
    bookings: [] as IBooking[],
    welfares: {} as IWELFARE,
  },
  reducers: {
    setWelfare: (state, action) => {
      state.welfare = action.payload;
    },
    setSelectedWelfare: (state, action) => {
      state.selectedWelfare = action.payload;
    },
    setSelectedWelfares: (state, action) => {
      state.welfares = action.payload;
    },

    emptyWelfareSlice: (state) => {
      state = {
        welfare: [] as IWELFARE[],
        selectedWelfare: {} as IWELFARE,
        bookings: [] as IBooking[],
        welfares: {} as IWELFARE,
      };
      return state;
    },
    addBooking: (state, action) => {
      if (!state.bookings) state.bookings = [];
      state.bookings.push(action.payload);
    },
    emptyBooking: (state) => {
      state.bookings = [];
    },
    default: (state) => {
      return {
        ...state,
      };
    },
    resetState: (state) => {
      state = {
        welfare: [] as IWELFARE[],
        selectedWelfare: {} as IWELFARE,
        bookings: [] as IBooking[],
        welfares: {} as IWELFARE,
      };
      return state;
    },
  },
});

export const welfareReducer = welfareSlice.reducer;

export const {
  setWelfare,
  setSelectedWelfare,
  emptyWelfareSlice,
  addBooking,
  emptyBooking,
  setSelectedWelfares,
} = welfareSlice.actions;

export const setSelectedLocation = (welfare: IWELFARE) => {
  return (dispatch: any) => {
    dispatch(setSelectedWelfare(welfare));
  };
};

export const fetchWelfare = (token: string | null) => {
  return (dispatch: any) => {
    Get({
      url: '/welfare/fetch-all-verified',
      token,
    })
      .then((res) => {
        dispatch(setWelfare(res.welfare));
        // .log(res);
      })
      .catch((error: any) => {
        throw error;
      });
  };
};
