import { createSlice } from '@reduxjs/toolkit';
import { CREW } from '../../utilities/dataHelper/hiring';

const crewCart = createSlice({
  name: 'crewCart',
  initialState: {
    temporaryCart: [] as CrewCartItem[],
    cart: [] as CrewCartItem[],
  } as CrewCart,
  reducers: {
    removeFromCart: (state, action: {payload: CrewCartItem | any}) => {
      state.cart = state.cart.filter(
        (item) => item.crewId !== action.payload.crewId,
      );
      state.temporaryCart = state.temporaryCart.filter(
        (item) => item.crewId !== action.payload.crewId,
      );
      return state;
    },
    addToCart: (state, action: { payload: string }) => {
      const itemExists = state.cart.find(eqItem => eqItem.crewId ===  action.payload);
      if (itemExists) state.cart = state.cart.filter((item) => item.crewId !== action.payload);
      
      const newItem = state.temporaryCart.find(eqItem => eqItem.crewId ===  action.payload);
     
      if (newItem) state.cart.push(newItem);
      state = {
        cart: state.cart,
        temporaryCart: state.temporaryCart,
      };

    },
    modifyCart: (state, action: { payload: CrewCartItem }) => {
      const item =
        state.temporaryCart &&
        state.temporaryCart.find(
          (transport) => transport.crewId === action.payload.crewId,
        );
      if (item) {
        item.crewId = action.payload.crewId;
        item.commencementDate = action.payload.commencementDate;
        item.expertiseNeeded = action.payload.expertiseNeeded;
        item.shootLocations = action.payload.shootLocations;
        item.productionCompanyName = action.payload.productionCompanyName;
        item.productionDuration = action.payload.productionDuration;
        item.productionType = action.payload.productionType;
        
        if (!item.servicesNeeded) {
          item.servicesNeeded = [];

          if (action.payload.currentCrew)
            item.servicesNeeded.push(action.payload.currentCrew);
        }

        if (action.payload.currentCrew) {
          const crewInCart = item.servicesNeeded?.find(
            (crew: { id: string | undefined; }) =>
              crew.id ===
              action.payload.currentCrew?.id,
          );
          if (!crewInCart) {
            item.servicesNeeded.push(action.payload.currentCrew);
          }
            
          item.servicesNeeded = item.servicesNeeded?.map((crw: { id: string | undefined; }) => {
            if (
              crw.id === action.payload.currentCrew?.id
            )
              return action.payload.currentCrew;
            return crw;
          });

          item.servicesNeeded = item.servicesNeeded?.filter(
            (crw: { productionBudget: number; }) => crw.productionBudget > 0,
          );
        }
      } else {
        state.temporaryCart.push(action.payload);
      }
    },
  },
});

export const crewCartReducer = crewCart.reducer;

export const { addToCart, removeFromCart, modifyCart } =
  crewCart.actions;

  export interface CrewCartItem {
    productionCompanyName: string | undefined;
    productionType: string | undefined;
    productionDuration: number | undefined;
    shootLocations: string | undefined;
    commencementDate: string | undefined;
    expertiseNeeded: string[] | any;
    servicesNeeded?: CrewService[] | any;
    crewId?: string | undefined | string[];
    crew?: CREW;
    currentCrew?: CrewService;
  }
  export interface CrewService {
    service: string;
    productionBudget: number;
    max: number | undefined;
    min: number | undefined;
    id: string;
  }

interface CrewCart {
  temporaryCart: CrewCartItem[];
  cart: CrewCartItem[];
}
