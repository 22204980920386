import { createSlice } from '@reduxjs/toolkit';
import { ILOCATION } from '../../utilities/index';


const cartSlice = createSlice({
  name: 'cart',
  initialState: [] as LocationProduction[],
  reducers: {
    addToCart: (state: LocationProduction[], action: { payload: LocationProduction }) => {
      let itemExists = state.find((item) => item.id === action.payload.id);
      if (itemExists) {
        state = state;
        const index = state.indexOf(itemExists);
        state[index] = action.payload;
        itemExists.quantity++;
      } else {
        state.push({ ...action.payload, quantity: 1 });
      }
    },
    removeFromCart: (state: ILOCATION[], action: { payload: LocationProduction }) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      state.splice(index, 1);
    },

    resetCart: (state) => {
      state.length = 0;
      
    },
    
  },
  // callTime: values.callTime,
});

export const cartReducer = cartSlice.reducer;

export interface LocationProduction extends ILOCATION {
  management: {
    productionCompany: string;
    productionTitle: string;
    jobTitle: string;
    productionStartDate: string;
    productionEndDate: string;
    productionType: string;
    repaint: boolean;
    numberOfPeople: number;
    checkInTime: string;
    checkoutTime: string;
    productionDuration: number;
    user: string;
    locationId: string;
    amount: number;
    wrapTime: string;
    callTime: string;
  }
}

export const {
  addToCart,
  removeFromCart,
  resetCart,
} = cartSlice.actions;



