import { createSlice } from '@reduxjs/toolkit';
import { IUSER } from '../../utilities';

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null } as AuthInitialState,
  reducers: {
    auth: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const authReducer = authSlice.reducer;

export const {
  auth, logOut,
} = authSlice.actions;


export interface AuthInitialState {
  user: IUSER | null;
  token: string | null;
}
