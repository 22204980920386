import { motion } from 'framer-motion';
import { ReactElement, SetStateAction, useState } from 'react';
import { ProfileMenuStyle } from './style';

const wrapperVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: 'afterChildren',
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      when: 'beforeChildren',
    },
  },
  closed: {
    opacity: 0,
    y: -15,
    transition: {
      when: 'afterChildren',
    },
  },
};

const actionIconVariants = {
  open: { scale: 1, y: 0 },
  closed: { scale: 0, y: -7 },
};

const Option = ({ text, icon, setOpen, handleClick }: { text: string, icon?: ReactElement, handleClick: () => void, setOpen: React.Dispatch<SetStateAction<boolean>> }) => {
  return (
    <motion.li
      variants={itemVariants}
      onClick={() => {
        handleClick();
        setOpen(false);
      }}
      className='menuOption'
    >
      <motion.span variants={actionIconVariants} >
        {icon}
      </motion.span>
      <span >{text}</span>
    </motion.li>
  );
};

const MarketMenu = ({ activePath }: { activePath: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <ProfileMenuStyle>
      <motion.div animate={open ? 'open' : 'closed'} className='framerClass'>
        <button
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          className={activePath === 'marketplace' ? 'marketMenu active' : 'marketMenu'}
          style={{ margin: 0 }}
        >
          Marketplace
        </button>
        <motion.ul
          initial={wrapperVariants.closed}
          variants={wrapperVariants}
          style={{ originY: 'top', translateX: '-50%' }}
          className='menuOptions'
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <Option setOpen={setOpen} text="Location" handleClick={() => window.location.href = '/marketplace'} key={1} />
          <Option setOpen={setOpen} text="Equipments" handleClick={() => window.location.href = '/marketplace/logistics/equipment'} key={2} />
          <Option setOpen={setOpen} text="Accommodation" handleClick={() => window.location.href = '/marketplace/logistics/accommodation'} key={3} />
          <Option setOpen={setOpen} text="Transportation" handleClick={() => window.location.href = '/marketplace/logistics/transportation'} key={4} />
          <Option setOpen={setOpen} text="Welfare" handleClick={() => window.location.href = '/marketplace/logistics/welfare'} key={5} />
          <Option setOpen={setOpen} text="Cast" handleClick={() => window.location.href = '/marketplace/hiring/cast'} key={7} />
          <Option setOpen={setOpen} text="Crew" handleClick={() => window.location.href = '/marketplace/hiring/crew'} key={8} />
        </motion.ul>
      </motion.div>
    </ProfileMenuStyle>
  );
};



export default MarketMenu;

