import { createSlice } from '@reduxjs/toolkit';
import { IMEDIA, IWELFARE } from '../../utilities/dataHelper/welfare';

const welfareCartSlice = createSlice({
  name: 'welfareCart',
  initialState: [] as WelfareCartItems[],
  reducers: {
    removeFromCart: (state, action) => {
      const index = state.findIndex((item) => item.welfareId === action.payload.welfareId);
      state.splice(index, 1);
    },
    addToCart: (state, action: { payload: WelfareCartItems  }) => {
      const item = state.find((items) => items.welfareId === action.payload.welfareId);
     

      if (item) {
        
        const index = state.indexOf(item);
        state[index] = action.payload;
        state = state;
      } else {
        state.push(action.payload);
      }
    },
    emptyWelfareCart: (state) => {
      state.length = 0;
    },
  },
});

export const welfareCartReducer = welfareCartSlice.reducer;
export const { addToCart, removeFromCart, emptyWelfareCart } = welfareCartSlice.actions;

export interface WelfareCartItems {
  welfareId: string;
  media: IMEDIA;
  stateOfShoot : string;
  cityOfShoot : string;
  commencementDate : string;
  duration: number;
  cast: number;
  welfare: IWELFARE;
  budget: number;
  days: number;
  numberOfItemNeeded: number;
  location: string;
  country: string;
  state: string;
  serviceType: string;
  addressOfDelivery: string;
  timeOfDelivery: string;
  dateOfDelivery: string;
  subTotal?: number;
  serviceDiscount: number;
  total: number;
}


