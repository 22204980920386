import { configureStore } from '@reduxjs/toolkit';
import { cartReducer } from './slices/cartSlice';
import storage from './storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { authReducer } from './slices/authSlice';
import { locationReducer } from './slices/locationSlice';
import { rentalsReducer } from './slices/equipmentSlice';
import { productReducer } from './slices/productSlice';
import { equipmentCartReducer } from './slices/equipmentsCart';
import { welfareCartReducer } from './slices/welfareCart';
import { welfareReducer } from './slices/welfareSlice';
import { welfareFoodCartReducer } from './slices/welfareFoodCart';
import { transportationReducer } from './slices/transportationSlice';
import { transportCartReducer } from './slices/transportationCart';
import { castReducer } from './slices/castSlice';
import { crewReducer } from './slices/crewSlice';
import { castBookingReducer } from './slices/castBookingSlice';
import { accommodationCartReducer } from './slices/accommodationCart';
import { crewCartReducer } from './slices/crewCart';




const reducers = combineReducers({
  cart: cartReducer,
  auth: authReducer,
  locations: locationReducer,
  rentals: rentalsReducer,
  transportation: transportationReducer,
  product: productReducer,
  equipmentsCart: equipmentCartReducer,
  welfare: welfareReducer,
  welfareCart: welfareCartReducer,
  welfareFoodCart: welfareFoodCartReducer,
  transportCart: transportCartReducer,
  cast: castReducer,
  crew: crewReducer,
  castBooking: castBookingReducer,
  accommodationCart: accommodationCartReducer,
  crewCart: crewCartReducer,

});


const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
