/* eslint-disable @typescript-eslint/naming-convention */
import { Typography } from 'antd';
import axios from 'axios';
import { FlutterwaveConfig } from 'flutterwave-react-v3/dist/types';
import { PaystackProps } from 'react-paystack/dist/types';
import Cookies from 'universal-cookie';
import { AuthInitialState } from '../redux/slices/authSlice';
import { stagingUrls } from './dataHelper/apiUrl';
import { IWELFARE } from './dataHelper/welfare';

const cookies = new Cookies();

export const AntText = Typography.Text;
export const { Title } = Typography;

export enum USER_ROLES {
  SCOUT = 'scout',
  OWNER = 'location owner',
  CLIENT = 'client',
  SERVICE_PROVIDER = 'service provider',
  CAST = 'cast',
  CREW = 'crew',
}

export const UserRoles = [
  // {
  //   value: USER_ROLES.SCOUT,
  //   title: 'Scout',
  // },
  {
    value: USER_ROLES.CLIENT,
    title: 'Client',
  },
  {
    value: USER_ROLES.SERVICE_PROVIDER,
    title: 'Service Provider',
  },
  {
    value: USER_ROLES.CAST,
    title: 'Cast',
  },
  {
    value: USER_ROLES.CREW,
    title: 'Crew',
  },
];

export interface ApiResponse {
  status: 'error' | 'success';
  statusCode: number;
  message: string;
  data: {
    accessToken: string;
  };
}

export interface AuthResponse {
  status: 'error' | 'success';
  statusCode: number;
  message: string;
  data: {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    image: null | string;
    accessToken: string;
  };
}

export const FetchToken = async (token: string): Promise<AuthInitialState> => {
  return axios
    .get(`${stagingUrls.apiBaseUrl}/auth/validate-token`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((res) => {
      const user: IUSER = res.data.data.user;
      return { user, token };
    })
    .catch(() => ({ user: null, token: null }));
};

export const DeleteToken = (): void => {
  return cookies.remove('admin_token');
};

export interface IUSER {
  email: string | null;
  userId: string | null;
  role: string | null;
  firstName: string | null;
  lastName: string | null;
  iat: number | null;
  exp: number | null;
  phoneNumber: string | null;
  isVerified: boolean;
  accessToken: string;
  otpId: string;
}

export interface ILOCATION {
  [x: string]: any;
  // [x: string]: any;
  id: string;
  name: string;
  listType: 'apartment' | 'commercial';
  buildingType: string;
  productionType?: string;
  totalRate?: number;
  country: string;
  state: string;
  city: string;
  currency: 'NGN' | 'GHS' | 'USD' | 'ZAR';
  available: boolean;
  address: string;
  painted: boolean;
  repaint: boolean | string;
  noiseLevel: NOISE_LEVEL;
  experienced: boolean;
  shootExperienced: string;
  shootListedFor: string;
  rate: number;
  houseRules: string;
  media: IMEDIA;
  cautionFee: number;
  status: LOCATION_STATUS;
  giveDiscount: boolean;
  discountPercentage: number;
  overnightAccommodation: boolean;
  apartment: IAPARTMENT;
  facilities: string;
  movingOut: string;
  rating: number;
  rateType: string;
  locationOwner: LocationOwner;
  quantity: number;
}

export interface IInspection extends ILOCATION {
  date: string;
  time: string;
}

export interface LocationOwner {
  createdAt: Date;
  id: string;
  contractAccepted: boolean;
  dateAccepted: Date;
  legalName: string;
  user: IUSER;
}

interface IAPARTMENT {
  id: string;
  numberOfBedRooms: string;
  numberOfToilets: string;
  numberOfLivingRooms: string;
  furnished: boolean | null;
  proofOfOwnership: boolean;
}

export enum NOISE_LEVEL {
  HIGH = 'high',
  LOW = 'low',
  MEDIUM = 'medium',
}

export enum LOCATION_STATUS {
  AVAILABLE = 'available',
  UNDER_REVIEW = 'under review',
  APPROVED = 'approved',
  IN_USE = 'in use',
  REJECTED = 'rejected',
  VERIFIED = 'verified',
}

interface MEDIA {
  type: 'img' | 'video';
  url: string;
}

interface IMEDIA {
  id: string;
  media: MEDIA[];
}

export const flwConfig = ({
  user,
  title,
  description,
  amount,
}: {
  user: IUSER;
  title: string;
  description: string;
  amount: number;
}) => {
  const res: FlutterwaveConfig = {
    public_key: process.env.NEXT_PUBLIC_FLW_KEY as string,
    tx_ref: `'${Date.now()}'`,
    amount,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: user?.email || 'test@email.com',
      phonenumber: user?.phoneNumber || '09038473489',
      name: `${user?.firstName} ${user?.lastName}`,
    },
    customizations: {
      title,
      description,
      logo: 'https://fmm-media.s3.af-south-1.amazonaws.com/logo.png',
    },
  };
  return res;
};

export const payStackConfig = ({
  user,
  amount,
  currency,
}: {
  user: IUSER;
  amount: number;
  currency: Currency;
}) => {
  const res: PaystackProps = {
    publicKey: process.env.NEXT_PUBLIC_PAYSTACK_KEY as string,
    reference: `${new Date().getTime().toString()}`,
    amount: amount * 100,
    currency,
    label: 'Filmmakers Mart',
    channels: ['card', 'bank', 'ussd', 'qr', 'mobile_money'],
    email: user?.email || 'test@email.com',
    phone: user?.phoneNumber || '09038473489',
    firstname: `${user?.firstName}`,
    lastname: `${user?.lastName}`,
  };
  return res;
};

export interface IScout {
  address: null | string;
  cityOfResidence: null | string;
  code: string;
  createdAt: string;
  entailsToBeLocationScout: null | string;
  firstPhoneNumber: null | string;
  id: string;
  image: null | string;
  name: string;
  occupation: null | string;
  priorExperience: null | string;
  productionExperience: null | string;
  resume: null | string;
  secondPhoneNumber: null | string;
  stateOfResidence: null | string;
  status: string;
  trainingSession: null | string;
}

export interface IBooking {
  scout_manager: IScout;
  createdAt: string;
  date: string;
  deletedAt: null | string;
  extras: null;
  id: string;
  inspection: Inspection;
  paymentId: null | string;
  paymentStatus: null | string;
  requestStatus: null | string;
  requestType: null | string;
  status: string;
  time: string;
  trans_ref: null | string;
  updatedAt: string;
  user: string | IUSER;
  location: ILOCATION;
  welfare: IWELFARE;
  management: null | IManagement;
}

declare type Currency = 'NGN' | 'GHS' | 'USD' | 'ZAR';

export interface IManagement {
  locationId: string;
  paymentId: string;
  trans_ref: string;
  paymentStatus: string;
  paymentType: string;
  productionCompany: string;
  productionTitle: string;
  jobTitle: string;
  secondaryEmail: string;
  productionStartDate: string;
  productionEndDate: string;
  productionDuration: string;
  productionType: string;
  repaint: boolean;
  numberOfPeople: number;
  project: string;
  callTime: string;
  wrapTime: string;
}

export interface Inspection {
  createdAt: string;
  date: string;
  deletedAt: null | string;
  extras: null | string;
  id: string;
  location: ILOCATION;
  paymentId: null | string;
  paymentStatus: null | string;
  status: string;
  time: string;
  trans_ref: null | string;
  updatedAt: string;
  user: IUSER;
}

export interface PaystackResponse {
  reference: string;
  trans: string;
  status: string;
  message: string;
  transaction: string;
  trxref: string;
  redirecturl: string;
}

export interface IDateTime {
  date: string;
  dateString: string;
  time: string;
  timeString: string;
}

export const fmmImages = {
  tobi: 'https://fmm-storage.s3.amazonaws.com/tobi.jpg',
  tomi: 'https://fmm-storage.s3.amazonaws.com/tomi.webp',
  trino: 'https://fmm-storage.s3.amazonaws.com/trino.webp',
  tunde: 'https://fmm-storage.s3.amazonaws.com/tunde.webp',
  user: 'https://fmm-storage.s3.amazonaws.com/user.webp',
  yemi: 'https://fmm-storage.s3.amazonaws.com/yemi.webp',
  dayo: 'https://fmm-storage.s3.amazonaws.com/dayo.webp',
  chioma: 'https://fmm-storage.s3.amazonaws.com/chioma.webp',
  changeMail: 'https://fmm-storage.s3.amazonaws.com/change-mail.svg',
  zero: 'https://fmm-storage.s3.amazonaws.com/degree.webp',
  dstv: 'https://fmm-storage.s3.amazonaws.com/dstv.webp',
  eric: 'https://fmm-storage.s3.amazonaws.com/eric.webp',
  family: 'https://fmm-storage.s3.amazonaws.com/familly.webp',
  ghana: 'https://fmm-storage.s3.amazonaws.com/ghana.webp',
  home1: 'https://fmm-storage.s3.amazonaws.com/home.webp',
  home2: 'https://fmm-storage.s3.amazonaws.com/home2.webp',
  home3: 'https://fmm-storage.s3.amazonaws.com/home3.webp',
  igho: 'https://fmm-storage.s3.amazonaws.com/igho.webp',
  jessica: 'https://fmm-storage.s3.amazonaws.com/jessy.webp',
  joshua: 'https://fmm-storage.s3.amazonaws.com/josh.webp',
  kenya: 'https://fmm-storage.s3.amazonaws.com/kenya.svg',
  kingsley: 'https://fmm-storage.s3.amazonaws.com/king.webp',
  logo: 'https://fmm-storage.s3.amazonaws.com/logo-black.webp',
  mobileLogo: 'https://fmm-storage.s3.amazonaws.com/mobileLogo.svg',
  nigeria: 'https://fmm-storage.s3.amazonaws.com/nigeria.webp',
  pic: 'https://fmm-storage.s3.amazonaws.com/pic.webp',
  placeholder: 'https://fmm-storage.s3.amazonaws.com/placeholder.webp',
  product: 'https://fmm-storage.s3.amazonaws.com/product.webp',
  southAfrica: 'https://fmm-storage.s3.amazonaws.com/south.webp',
  frame: 'https://fmm-storage.s3.amazonaws.com/frame.webp',
};
